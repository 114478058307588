import React from 'react'
import { Link } from 'gatsby'

// Images 
import logoImg from '../../assets/images/logo-2.png'

const ComingSoon = () => {
    const [days, setDays] = React.useState('');
    const [hours, setHours] = React.useState('');
    const [minutes, setMinutes] = React.useState('');
    const [seconds, setSeconds] = React.useState('');

    React.useEffect(() => {
        const interval = setInterval(() => { 
            commingSoonTime();
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    const commingSoonTime = () => {
        let endTime = new Date("August 23, 2022 17:00:00 PDT");
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.floor(timeLeft / 86400); 
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
    }

    return (
        <div className="coming-soon">
            <div className="coming-soon-overlay pt-100 pb-100">
                <div className="container">
                    <div className="coming">
                        <Link to="/">
                            <img 
                                src={logoImg} 
                                alt="logo" 
                            />
                        </Link>
                        <h3>Get Ready To Meet With Us</h3>
                        <div className="countdown-wrapper">
                            <div id="timer" className="getting-start flex-wrap d-flex justify-content-center">
                                <div id="days" className="getting-start align-items-center flex-column d-flex justify-content-center">{days} <span>Days</span></div>
                                <div id="hours" className="getting-start align-items-center flex-column d-flex justify-content-center">{hours} <span>Hours</span></div>
                                <div id="minutes" className="getting-start align-items-center flex-column d-flex justify-content-center">{minutes} <span>Minutes</span></div>
                                <div id="seconds" className="getting-start align-items-center flex-column d-flex justify-content-center">{seconds} <span>Seconds</span></div>
                            </div>
                        </div>
                        <form className="newsletter-form" data-toggle="validator">
                            <div className="row ">
                                <div className="col-md-8 col-sm-7 col-12">
                                    <input 
                                        type="email" 
                                        className="input-newsletter form-control"  
                                        placeholder="Enter Your Email" 
                                        name="EMAIL" required
                                        autoComplete="off"
                                    />
                                    <div id="validator-newsletter1" className="form-result"></div>
                                </div>
                                <div className="col-md-4 col-sm-5 col-12">
                                    <button className="default-button" >Subscribe Now <i className="fas fa-arrow-right"></i></button>
                                </div>
                            </div>
                        </form>
                        <p>We are working very hard on the new version of our site. <br/>  It will bring a lot of new features. Stay tuned!</p>
                        <div className="social-icons">
                            <ul>
                            <li><a href="https://www.facebook.com/" rel="noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.linkedin.com/" rel="noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="https://twitter.com/" rel="noreferrer" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/" rel="noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon
