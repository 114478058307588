import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'


import ComingSoon from '../components/Common/ComingSoon'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'


const ComingSoonPage = () => {
    return (
        <Layout>
            <Seo title="Coming Soon" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Coming Soon" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Coming Soon" 
            />

            <ComingSoon />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default ComingSoonPage